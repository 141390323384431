<template>
  <el-dialog
      :append-to-body="true"
      :visible="dialogShow"
      title="支付状态修改"
      width="500px"
      :before-close="handleClose"
  >
    <el-form
        ref="form"
        :model="form"
        size="small"
        label-width="120px"
    >
      <el-form-item label="申请编号">
        <el-input
            :value="rowData.transactionId"
            style="width: 250px;"
            :disabled="true"
        />
      </el-form-item>
      <el-form-item
          label="修改前状态"
      >
        <el-input
            :value="befStatusDesc"
            style="width: 250px;"
            :disabled="true"
        />
      </el-form-item>
      <el-form-item label="修改后状态">
        <el-select
            v-model="form.updateStatus"
            placeholder="请选择状态"
        >
          <el-option
              v-for="item in payStatusOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付凭证">
        <el-upload
            class="upload"
            action="''"
            :accept="getLimitUploadImageFormat()"
            :file-list="fileList"
            :http-request="handleSuccess"
            :before-upload="beforeUpload"
            :on-preview="handlePreview"
            :limit=1
            list-type="picture"
        >
          <!--:on-success="handleSuccess"-->
          <el-button size="small" type="primary">点击上传查账凭证</el-button>
          <div slot="tip" class="el-upload__tip"><uploadImgTips></uploadImgTips></div>
        </el-upload>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" :rows="2" style="width: 250px;"/>
      </el-form-item>
    </el-form>
    <div lot="footer" class="dialog-footer" style="text-align: right;">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button
          :loading="loading"
          type="primary"
          @click="doSubmitPayStatus"
      >修改</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { modifyPayStatus } from '@/api/cus/tCustDepositWithdraw'

let orginForm = {
  id: '',
  transactionId: '',
  chkStatus: 1,
  updateStatus:1,

  payImg:'',
  remark:''
};
import { uploadFile } from '@/api/data'
import { mapGetters } from 'vuex'
import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile,gePayTypeCommon,parseTime } from '@/utils/index'
export default {
  name: 'payStatusAdjustDialog',
  props: {
    dialogShow: {
      type: Boolean,
      default(){
        return false;
      }
    },
    rowData:{
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      fileList:[],
      form: JSON.parse((JSON.stringify(orginForm))),
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['payStatusOptions']),
    befStatusDesc(){
      if(!this.rowData.id) {
        return ''
      }else {
        if(this.payStatusOptions.length){
          let filterData =  this.payStatusOptions.filter((item,index) => {

            return item['key'] == this.rowData.payStatus;
          });
          if(filterData.length){
            return filterData[0].display_name;
          }
        }else {
            return '';
        }
      }
    },
  },
  watch:{
    rowData:{
      handler(newValue,oldValue){
        if(newValue.id){
          this.agssien(newValue);
        }
      },
      deep: true,
    }
  },
  methods: {
    getLimitUploadImageFormat,
    handleSuccess(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.payImg = res.data.content[0].url;
        });
      });
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    agssien(data){
      this.form.id = data.id;
      this.form.transactionId = data.transactionId;
      this.form.status = data.payStatus;
      this.form.updateStatus = data.payStatus;
    },
    doSubmitPayStatus(){

      if(this.form.updateStatus == 3 && (this.form.payImg==null || this.form.payImg=='')){
        this.$notify({
          title: '调整为支付成功，需上传查账凭证',
          type: 'error',
          duration: 2500
        })
        return;
      }
      const params = {
        id: this.rowData.id,
        payStatus: this.form.updateStatus,
        payPic:this.form.payImg
      }

      //接口调用
      this.loading = true;
      modifyPayStatus(params).then(res => {
        this.loading = false;
        this.$notify({
          title: '支付状态修改成功',
          type: 'success',
          duration: 2500
        })
        this.$emit('edit-success');
      }).catch(err => {
        this.cancel()
        this.loading = false
      })
    },
    reset(){
      this.form = JSON.parse((JSON.stringify(orginForm)));
      this.fileList = [];
    },
    close(){
      this.$emit('close');
    },
    cancel(){
      this.reset();
      this.close();
    },
    handleClose(){
      this.close();
    }
  }
}
</script>

<style scoped>

</style>
